import styled from 'styled-components';

export default styled.div`
  display: block;
  box-sizing: border-box;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-bottom: 1rem;

  .component-checkbox {
    padding: 1.5rem 1rem;
  }
`;
