import { FormattedMessage, useIntl } from 'gatsby-plugin-react-intl';
import React, { FC } from 'react';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { setDelivery, setPublish } from '../../../redux/slices/egiftCardSlice';
import { HintStyle } from '../VoucherSettingComponent/style';

import Style from './style';

import { Checkbox } from '@components/common';
import { FormHeaderStyle, MyPageFormStyle } from '@features/mypage/UserConfirm/style';

const OtherSettingComponent: FC = () => {
  const intl = useIntl();
  const publish = useAppSelector((state) => state.eGiftCardReducer.publish);
  const delivery = useAppSelector((state) => state.eGiftCardReducer.delivery);
  const dispatch = useAppDispatch();

  return (
    <>
      <MyPageFormStyle>
        <FormHeaderStyle>
          <FormattedMessage id="product.public.setting" />
        </FormHeaderStyle>
        <Style>
          <HintStyle>
            <FormattedMessage id="e-gift.hint8" />
          </HintStyle>
          <div className="component-checkbox">
            <Checkbox
              value={true}
              title={intl.formatMessage({ id: 'product.public.enabled' })}
              checked={!!publish}
              onClick={(value) =>
                dispatch(
                  setPublish({
                    publish: Boolean(value),
                  })
                )
              }
            />
            <Checkbox
              value={false}
              title={intl.formatMessage({ id: 'product.public.disabled' })}
              checked={!publish}
              onClick={(value) =>
                dispatch(
                  setPublish({
                    publish: Boolean(value),
                  })
                )
              }
            />
          </div>
        </Style>
      </MyPageFormStyle>
      <MyPageFormStyle>
        <FormHeaderStyle>
          <FormattedMessage id="breadcrumbs.address" />
        </FormHeaderStyle>
        <Style>
          <HintStyle>
            <FormattedMessage id="e-gift.hint9" />
            <FormattedMessage id="e-gift.hint10" />
          </HintStyle>
          <div className="component-checkbox">
            <Checkbox
              value="enabled"
              title={intl.formatMessage({ id: 'delivery.option1' })}
              checked={delivery === 'enabled'}
              onClick={(value) =>
                dispatch(
                  setDelivery({
                    delivery: String(value),
                  })
                )
              }
            />
            <Checkbox
              value="disabled"
              title={intl.formatMessage({ id: 'delivery.option2' })}
              checked={delivery === 'disabled'}
              onClick={(value) =>
                dispatch(
                  setDelivery({
                    delivery: String(value),
                  })
                )
              }
            />
          </div>
        </Style>
      </MyPageFormStyle>
    </>
  );
};

export default React.memo(OtherSettingComponent);
