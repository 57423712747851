import styled from 'styled-components';

export default styled.div`
  display: block;
  margin: auto;
  box-sizing: border-box;
  padding: 1.5rem 1rem;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  background: #ffffff;
  border: 1px solid #f39800;
  box-shadow: 3px 4px 10px rgba(100, 83, 54, 0.1);
  border-radius: 10px;

  .component-summary {
    p {
      margin-bottom: 0px;
      display: inline-flex;
      width: 100%;
      justify-content: space-between;
    }
  }
`;

export const ItemDetailStyle = styled.div`
  display: inline-flex;
  width: 100%;
  margin: 1rem 0px;
  text-align: left;

  & > img {
    object-fit: contain;
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 10px;
  }

  & > .component-item-title {
    flex: 1;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: left;
    padding: 0px 0.5rem;
    box-sizing: border-box;
  }
`;

export const MoreButtonStyle = styled.button<{ active: boolean }>`
  width: 100%;
  background: transparent !important;
  text-decoration-line: none;
  color: #707070 !important;
  padding: 10px 15px;
  display: block;
  box-sizing: border-box;
  font-family: 'Hiragino Kaku Gothic ProN', 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center !important;
  margin: 0px;
  transition: opacity 0.3s, color 0.3s, border 0.3s, background 0.3s;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    right: 5px;
    top: 40%;
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(${(props) => (props.active ? 225 : 45)}deg);
  }
`;
