import { FormattedMessage, useIntl } from 'gatsby-plugin-react-intl';
import React, { FC } from 'react';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { setVoucher } from '../../../redux/slices/egiftCardSlice';

import { HintStyle, VoucherStyle } from './style';

import { Checkbox } from '@components/common';
import { Grid } from '@components/layout/grid';
import { FormHeaderStyle, MyPageFormStyle } from '@features/mypage/UserConfirm/style';

const VoucherSettingComponent: FC = () => {
  const intl = useIntl();
  const voucher = useAppSelector((state) => state.eGiftCardReducer.voucher);
  const dispatch = useAppDispatch();

  return (
    <MyPageFormStyle>
      <FormHeaderStyle>
        <FormattedMessage id="voucher.setting" />
      </FormHeaderStyle>
      <Grid md={{ gridTemplateColumns: 'repeat(2, 211px)', gap: '0px 40px' }}>
        <Checkbox
          value="custom"
          checked={voucher === 'custom'}
          onClick={(value) =>
            dispatch(
              setVoucher({
                voucher: String(value),
              })
            )
          }
          title={intl.formatMessage({ id: 'custom' })}
        />
        <Checkbox
          value="anonymous"
          checked={voucher === 'anonymous'}
          onClick={(value) =>
            dispatch(
              setVoucher({
                voucher: String(value),
              })
            )
          }
          title={intl.formatMessage({ id: 'anonymous' })}
        />
        <VoucherStyle>
          <div className="voucher-title">
            <FormattedMessage id="voucher" />
          </div>
          <table className="voucher-table">
            <tbody>
              <tr>
                <th>
                  <FormattedMessage id="delivery.home" />:
                </th>
                <td>あなたの住所</td>
              </tr>
              <tr>
                <th>
                  <FormattedMessage id="delivery.name" />:
                </th>
                <td>あなたのお名前</td>
              </tr>
              <tr>
                <th>
                  <FormattedMessage id="delivery.full.name" />:
                </th>
                <td>あなたの住所</td>
              </tr>
            </tbody>
          </table>
        </VoucherStyle>
        <VoucherStyle>
          <div className="voucher-title">
            <FormattedMessage id="voucher" />
          </div>
          <table className="voucher-table">
            <tbody>
              <tr>
                <th>
                  <FormattedMessage id="delivery.home" />:
                </th>
                <td>Tasty</td>
              </tr>
              <tr>
                <th>
                  <FormattedMessage id="delivery.name" />:
                </th>
                <td>Tasty事務局</td>
              </tr>
              <tr>
                <th>
                  <FormattedMessage id="delivery.full.name" />:
                </th>
                <td>ニックネーム</td>
              </tr>
            </tbody>
          </table>
        </VoucherStyle>
        <HintStyle>
          <FormattedMessage id="e-gift.hint7" />
        </HintStyle>
      </Grid>
    </MyPageFormStyle>
  );
};

export default React.memo(VoucherSettingComponent);
