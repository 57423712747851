import { FormattedMessage } from 'gatsby-plugin-react-intl';
import React, { FC } from 'react';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { setPurchaser } from '../../../redux/slices/egiftCardSlice';

import { GiftIcon } from '@assets/image/png';
import { ReactComponent as CartIcon } from '@assets/image/svg/cart.svg';
import { Layout } from '@components/layout';
import { Flex } from '@components/layout/flexbox';
import {
  PurchaserInfoComponent,
  VoucherSettingComponent,
  OtherSettingComponent,
} from '@features/e-cart';
import { Style } from '@features/e-cart/style';
// import useCart from '@hooks/useCart';

const EGiftAddressInfoPage: FC = () => {
  // const { eGiftCart, loadEGiftCart } = useCart();
  // const { user, loadUser } = useUser();
  const purchaser = useAppSelector((state) => state.eGiftCardReducer.purchaser);
  const dispatch = useAppDispatch();

  // useEffect(() => {
  //   if (!eGiftCart) {
  //     loadEGiftCart('0');
  //   }
  // }, [eGiftCart, loadEGiftCart]);

  // useEffect(() => {
  //   if (!user) {
  //     loadUser();
  //   } else {
  //     const purchaser: Purchaser = {
  //       id: '0',
  //       email: user.email,
  //       first_name: user.first_name || '',
  //       prefecture: user.prefecture || '',
  //       building: user.building || '',
  //       last_name: user.last_name || '',
  //       first_kana: user.first_kana || '',
  //       last_kana: user.last_kana || '',
  //       address: user.address || '',
  //       postcode: user.postcode || '',
  //       tel: user.tel || '',
  //     };
  //     dispatch(
  //       setPurchaser({
  //         purchaser,
  //       })
  //     );
  //   }
  // }, [user, loadUser, dispatch]);

  return (
    <Layout>
      <Style.Wrap style={{ padding: '0px' }}>
        <Style.Container className="bg-white left" style={{ padding: '10px 5px' }}>
          <Style.GiftHeader>
            <div className="card-icon">
              <CartIcon width="20px" height="20px" stroke="orange" />
            </div>
            <FormattedMessage id="delivery.info" />
            <div className="sub-title">
              <img src={GiftIcon as string} alt="GiftIcon" />
              <FormattedMessage id="breadcrumbs.e-gift" />
            </div>
          </Style.GiftHeader>
          <Flex direction="column" alignItems="flex-start">
            {purchaser && (
              <PurchaserInfoComponent
                purchaser={purchaser}
                onChange={(purchaser) => {
                  dispatch(setPurchaser({ purchaser }));
                }}
              />
            )}
            <VoucherSettingComponent />
            {/* <Col className="e-gift-price-summary" sm={12}>
              <PriceSummaryComponent eGiftCart={eGiftCart} />
            </Col> */}
            <OtherSettingComponent />
          </Flex>
        </Style.Container>
      </Style.Wrap>
    </Layout>
  );
};

export default React.memo(EGiftAddressInfoPage);
