import { FormattedMessage, useIntl } from 'gatsby-plugin-react-intl';
import React, { FC } from 'react';

import { Purchaser } from '@api/userService';
import { Input } from '@components/common';
import {
  FormHeaderStyle,
  FormTableStyle,
  MyPageFormStyle,
} from '@features/mypage/UserConfirm/style';

const postcodeRegex = /^[0-9]+$/;
const emailRegex =
  /^$|^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(?:[a-zA-Z]{2}|com|org|net|edu|gov|mil|biz|info|mobi|name|aero|asia|jobs|museum)$/;
const phoneRegex = /^(?!0+$)(?!0*\.0*$)\d{1,10}(\.\d{1,10})?$/;

interface Props {
  purchaser: Purchaser;
  onChange: (purchaser: Purchaser) => void;
}

const PurchaserInfoComponent: FC<Props> = ({ purchaser, onChange }) => {
  const intl = useIntl();
  return (
    <MyPageFormStyle>
      <FormHeaderStyle>
        <FormattedMessage id="e-gift.purchaser" />
      </FormHeaderStyle>
      <FormTableStyle>
        <tbody>
          <tr>
            <td className="required">
              <FormattedMessage id="user.name" />
            </td>
            <td width="80%">
              <Input
                value={
                  (purchaser.first_name ? `${purchaser.first_name} ` : '') +
                  (purchaser.last_name || '')
                }
                errorMessage={intl.formatMessage(
                  { id: 'format.require' },
                  { e: intl.formatMessage({ id: 'user.name' }) }
                )}
                onChange={(value) => {
                  const values = value.split(' ');

                  if (values.length > 2) {
                    values[1] = values.slice(1, values.length).join('');
                    values.splice(2, values.length);
                  }
                  onChange({
                    ...purchaser,
                    first_name: values[0],
                    last_name: values[1] || '',
                  });
                }}
              />
            </td>
          </tr>
          <tr>
            <td className="required">
              <FormattedMessage id="mail.address" />
            </td>
            <td width="80%">
              <Input
                value={purchaser.email || ''}
                pattern={emailRegex}
                errorMessage={intl.formatMessage(
                  { id: 'format.error' },
                  { e: intl.formatMessage({ id: 'mail.address' }) }
                )}
                onChange={(value) => {
                  onChange({
                    ...purchaser,
                    email: value,
                  });
                }}
              />
            </td>
          </tr>
          <tr>
            <td className="required">
              <FormattedMessage id="user.postcode" />
            </td>
            <td>
              <Input
                value={purchaser.postcode || ''}
                pattern={postcodeRegex}
                errorMessage={intl.formatMessage(
                  { id: 'format.error' },
                  { e: intl.formatMessage({ id: 'user.postcode' }) }
                )}
                onChange={(value) => {
                  onChange({
                    ...purchaser,
                    postcode: value,
                  });
                }}
              />
            </td>
          </tr>
          <tr>
            <td className="required">
              <FormattedMessage id="country" />
            </td>
            <td>
              <Input
                value={purchaser.prefecture || ''}
                errorMessage={intl.formatMessage(
                  { id: 'format.error' },
                  { e: intl.formatMessage({ id: 'country' }) }
                )}
                onChange={(value) => {
                  onChange({
                    ...purchaser,
                    prefecture: value,
                  });
                }}
              />
            </td>
          </tr>
          <tr>
            <td className="required">
              <FormattedMessage id="user.address" />
            </td>
            <td>
              <Input
                value={purchaser.address || ''}
                errorMessage={intl.formatMessage(
                  { id: 'format.require' },
                  { e: intl.formatMessage({ id: 'user.address' }) }
                )}
                onChange={(value) => {
                  onChange({
                    ...purchaser,
                    address: value,
                  });
                }}
              />
            </td>
          </tr>
          <tr>
            <td className="required">
              <FormattedMessage id="building" />
            </td>
            <td>
              <Input
                value={purchaser.building || ''}
                errorMessage={intl.formatMessage(
                  { id: 'format.require' },
                  { e: intl.formatMessage({ id: 'building' }) }
                )}
                onChange={(value) => {
                  onChange({
                    ...purchaser,
                    building: value,
                  });
                }}
              />
            </td>
          </tr>
          <tr>
            <td className="required">
              <FormattedMessage id="user.tel" />
            </td>
            <td>
              <Input
                value={purchaser.tel || ''}
                pattern={phoneRegex}
                errorMessage={intl.formatMessage(
                  { id: 'format.error' },
                  { e: intl.formatMessage({ id: 'user.tel' }) }
                )}
                onChange={(value) => {
                  onChange({
                    ...purchaser,
                    tel: value,
                  });
                }}
              />
            </td>
          </tr>
        </tbody>
      </FormTableStyle>
    </MyPageFormStyle>
  );
};

export default React.memo(PurchaserInfoComponent);
