import styled from 'styled-components';

import { breakpoint } from '@constants/theme';

export const MyPageFormStyle = styled.div`
  padding: 1rem 0px;
  box-sizing: border-box;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
`;

export const FormHeaderStyle = styled.div`
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  padding: 0rem 0.5rem;
  margin-bottom: 1rem;
  text-align: left;
  border-left: 2px solid var(--color-bg-gray);
  box-sizing: border-box;
  color: #000000;
`;

export const FormTableStyle = styled.table`
  width: 100%;
  margin-bottom: 1.5rem;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  box-sizing: border-box;
  color: #000000;
  text-align: left;

  tr {
    td {
      padding: 0.5rem 0rem 0.5rem 0rem;

      &:first-child {
        width: auto;
        white-space: nowrap;
        padding-right: 1rem;
      }
    }
  }

  @media (max-width: ${breakpoint.md}px) {
    tbody.edit {
      display: block;
      width: 100%;
    }

    tr.edit {
      display: block;
      width: 100%;
      padding: 0px;
      box-sizing: border-box;

      td {
        display: block;
        width: 100%;
        box-sizing: border-box;

        &:first-child {
          padding-bottom: 0px;
        }
      }
    }
  }
`;
